<template>
  <Stack direction="col" align="center" justify="center" :breakpoints="mainContainerBreakpoints" gap="lg" class="my-xl">
    <Stack direction="col" class="px-xs w-full md:w-[516px]" gap="md">
      <Banner
        data-test-id="loginCredentialsError"
        v-if="currentError"
        @dismiss="clearError"
        variant="error"
        data-fs="loginCredentialsError"
      >
        {{ currentError.message }}
      </Banner>
      <Banner v-if="passwordReset" variant="success">
        Your password has been successfully reset. You can now sign in with your new password.
      </Banner>
      <form class="w-full flex flex-col gap-md" @submit.prevent="onSubmit">
        <Input
          id="email"
          data-test-id="loginEmailInput"
          size="md"
          :model-value="email"
          label="Email Address"
          data-validation-rule="email"
          @update:input-value="email = String($event).trim()"
          @blur="vee.validateSingleField($event)"
          :error="vee.fieldHasError('email').value"
          :message-text="vee.fieldError('email').value"
          data-fs="loginEmailInput"
        />
        <Input
          id="password"
          data-test-id="loginPasswordInput"
          size="md"
          :model-value="password"
          label="Password"
          is-password
          link-text="Forgotten password?"
          link-href="/forgotten-password"
          link-class="text-mkm-blue-light text-sm"
          :link-tag="NuxtLink"
          @update:input-value="password = String($event)"
          data-validation-rule="password"
          @blur="vee.validateSingleField($event)"
          :error="vee.fieldHasError('password').value"
          :message-text="vee.fieldError('password').value"
        />

        <Stack direction="col" class="w-full" gap="none">
          <Button
            data-test-id="signInButton"
            size="md"
            variant="secondary"
            class="min-w-full justify-center"
            data-fs="loginButton"
            :disabled="isLoggingIn"
          >
            SIGN IN
            <Spinner v-if="isLoggingIn" />
          </Button>
        </Stack>
      </form>
    </Stack>
    <!-- Login Rationale -->
    <br-component component="left" />
  </Stack>
</template>

<script setup lang="ts">
import { ROUTES } from "~/helpers/routes/routes";

const NuxtLink = resolveComponent("NuxtLink");
const router = useRouter();

const { routerHistory } = useRouterHistory();
const vee = useVeeValidate();
const route = useRoute();

const { email, password, isLoggingIn, currentError, handleLogin, clearError } = useLoginHandler();

const mainContainerBreakpoints: Object = { align: { md: "start" }, direction: { md: "row" }, gap: { md: "3xl" } };
const passwordReset = ref<boolean>(false);

onMounted(() => {
  if (route.query.q === "passwordResetSuccess") {
    passwordReset.value = true;
  }
});

const onSubmit = async () => {
  const { success } = await handleLogin(false);

  if (success) {
    const previousPath = routerHistory.value as ROUTES;
    const noReturnRoutes = [
      ROUTES.CREATE_CASH_SUCCESS,
      ROUTES.SET_PASSWORD,
      ROUTES.RESET_PASSWORD,
      ROUTES.FORGOTTEN_PASSWORD,
    ];

    router.push(previousPath && !noReturnRoutes.includes(previousPath) ? previousPath : ROUTES.ACCOUNT_DASHBOARD);
  }
};
</script>
