<template>
  <div class="shadow-[0_4px_3px_rgba(0,0,0,0.25)] mx-auto md:max-w-[96%] lg:w-[1240px] my-sm md:my-2xl bg-white pt-xs">
    <Tabs :active-tab="authTab" align="center" border-bottom class="text-charcoal-default">
      <Tab title="Sign In" data-test-id="signInTab">
        <Login />
      </Tab>
      <Tab title="Create Account" data-test-id="createAccountTab">
        <br-component component="right" />
      </Tab>
    </Tabs>
  </div>
</template>

<script lang="ts" setup>
definePageMeta({
  middleware: ["is-not-authenticated"],
});

const route = useRoute();
const authTab = computed(() => (route.query.signup ? "Create Account" : "Sign In"));

useHead({
  bodyAttrs: {
    class: "bg-neutral-lighter",
  },
});
</script>
